<template>
  <div :id="`TASK_CONSTRAINT`" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="md" :title="$t('task.constraint.title')" footer-class="footerClass" 
      no-close-on-backdrop  content-class="shadow"
      @hidden="hidden">

      <b-alert variant="danger" dismissible v-model="alertError">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ $t('error.attention_required') }} 
      </b-alert>

      <b-form-group id="task-constraint" :label="$t('task.field.constraint')" label-for="task-constraint_type">
        <b-row>
          <b-col cols="12" xl="6" class="mb-2 mb-xl-0">
            <multiselect v-model="type" class="custom-dropdown-options enable-option-icon"
              :max-height="300"
              :options="optionConstraint.map(i => i.value)"
              :custom-label="getConstraintTypeOptionLabel"
              :placeholder="''"
              :searchable="false" 
              :allow-empty="false"
              :showLabels="false"
              @input="constraintTypeChanged">
              <template slot="option" slot-scope="props">
                <font-awesome-icon class="selected-option-icon" v-if="type == props.option" :icon="['far', 'check']" />
                <span class="option__title">{{ getConstraintTypeOptionLabel(props.option) }}</span>
              </template>
            </multiselect>
          </b-col>
          <b-col cols="12" xl="6" class="pl-xl-0">
            <b-form-datepicker id="task-constraintDate" v-model="date"
              :disabled="disableConstraintDate"
              today-button
              reset-button
              close-button
              hide-header
              :label-today-button="$t('date.today')"
              :label-reset-button="$t('date.reset')"
              :label-close-button="$t('date.close')"
              today-button-variant="primary"
              reset-button-variant="danger" 
              close-button-variant="secondary"
            >
              <template v-slot:button-content="{ }">
                <font-awesome-icon :icon="['far', 'calendar-days']" />
              </template>
            </b-form-datepicker>
          </b-col>
        </b-row>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDateError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('date') }}
        </b-form-invalid-feedback>
      </b-form-group>
      

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);
import { fieldValidateUtil } from '@/script/helper-field-validate';
import Multiselect from 'vue-multiselect';

export default {
  name: 'TaskConstraintModal',
  components: {
    Multiselect
  },
  props: {
    constraintType: {
      type: String,
      default: null
    },
    constraintTime: {
      type: Number,
      default: null
    },
    show: {
      type: Boolean,
      required: true
    },
    optionConstraint: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      permissionName: 'TASK',
      modalShow: false,
      alertError: false,
      type: null,
      date: null,
    }
  },
  created() {
    this.fieldValidateUtil = fieldValidateUtil;
    this.updateShow(this.show);
    this.updateType(this.constraintType);
    this.updateDate(this.constraintTime);
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
  },
  watch: {
    show(newValue) {
      this.updateShow(newValue);
      if(!newValue) {
        this.resetProperties();
      }
    },
    constraintType(newValue) {
      this.updateType(newValue);
    },
    constraintTime(newValue) {
      this.updateDate(newValue);
    }
  },
  computed: {
    disableConstraintDate() {
      return this.type === 'As_late_as_possible' || this.type === 'As_soon_as_possible';
    },
    showDateError() {
      return fieldValidateUtil.hasError(this.errors, 'date');
    },
  },
  methods: {
    updateShow(newValue) {
      this.modalShow = newValue;
    },
    updateType(newValue) {
      this.type = newValue;
      this.$nextTick(() => {
        if (this.disableConstraintDate) {
          this.date = null;
        }
      });
    },
    updateDate(newValue) {
      if(newValue != null && !this.disableConstraintDate) {
        this.date = newValue !== 0 ? moment.utc(newValue).format('YYYY-MM-DD') : moment.utc().format('YYYY-MM-DD');
      } else {
        this.date = null;
      }
    },
    ok() {
      this.errors.clear();
      if (this.date == '') {
        this.date = null;
      }
      if(this.disableConstraintDate) {
        this.date = null;
      } else if (this.date == null) {
        this.errors.add({
          field: 'date',
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('task.constraint.field.date')])
        });
      }

      this.$nextTick(() => {
        if (this.errors.items.length < 1) {
          let time = 0;
          if(this.date != null && this.date.trim().length > 0) {
            time = moment.utc(`${this.date} 00:00:00`, 'YYYY-MM-DD HH:mm:ss').valueOf();
          }
          this.$emit('ok', { constraintType: this.type, constraintTime: time });
          this.$emit('update:show', false);
        } else {
          this.alertError = true;
        }
      });
      
      
      
      
    },
    hidden() {
      this.$emit('update:show', false);
    },
    constraintTypeChanged(value) {
      if('As_late_as_possible' === value || 'As_soon_as_possible' === value) {
        this.$set(this, 'date', null);
      }
    },
    resetProperties() {
      this.type = null;
      this.date = null;
      this.alertError = false;
      this.errors.clear();
    },
    getConstraintTypeOptionLabel(value) {
      return this.optionConstraint.find(i => i.value === value)?.text || value;
    }
  }

}
</script>

<style lang="scss">
@media (min-width: 576px) {
  .modal-md {
    max-width: 550px !important;
  }
}
</style>
